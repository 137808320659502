<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>Roles</h1>
            </b-col>
            <b-col class="text-right">
                <b-button v-b-toggle.sidebar-backdrop>Create new role</b-button>
            </b-col>
        </b-row>
        <b-card>
            <b-table striped hover :items="items" :fields="fields">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'EditRole', params: { roleId: data.item.id } }">{{
                        data.item.name
                    }}</router-link>
                </template>
                <template #cell(delete)="data">
                    <div class="text-right">
                        <b-icon-trash @click="confirmDelete(data.item.id)"></b-icon-trash>
                    </div>
                </template>
            </b-table>
        </b-card>
        <b-sidebar
            id="sidebar-backdrop"
            v-model="createVisible"
            :backdrop-variant="'dark'"
            backdrop
            shadow
            right
            @shown="showCreateRole"
        >
            <div class="px-3 py-2">
                <h3>Create Role</h3>
                <b-form-group label="Name" label-for="role-name">
                    <b-form-input id="role-name" v-model="name" ref="focusName" type="text"></b-form-input>
                </b-form-group>
                <b-row>
                    <b-col>
                        <b-button variant="info" @click="createRole">{{ $t('buttons.submit') }}</b-button>
                    </b-col>
                    <b-col class="text-right">
                        <b-button variant="secondary" v-b-toggle.sidebar-backdrop>{{ $t('buttons.cancel') }}</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import httpClient from '@/services';

export default {
    name: 'RolesSettings',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'delete',
                    label: '',
                    sortable: false
                }
            ],
            items: [],
            name: null,
            createVisible: false
        };
    },
    mounted() {
        this.getRoles();
    },
    methods: {
        getRoles() {
            httpClient
                .get('/roles')
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        createRole() {
            httpClient
                .post('/roles', { name: this.name })
                .then(() => {
                    this.name = null;
                    this.getRoles();
                    this.createVisible = false;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        confirmDelete(roleId) {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this role?', {
                    title: 'Please Confirm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.deleteRole(roleId);
                    }
                })
                .catch(error => {
                    // An error occurred
                    console.error(error);
                });
        },
        deleteRole(roleId) {
            httpClient
                .delete(`/roles/${roleId}`)
                .then(() => {
                    this.getRoles();
                })
                .catch(error => {
                    console.error(error);
                });
        },
        showCreateRole() {
            this.$refs.focusName.focus();
        }
    }
};
</script>
