<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>Edit Role</h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3">
                <b-form-group label="Name" label-for="role-name">
                    <b-form-input
                        id="role-name"
                        v-model="$v.role.name.$model"
                        type="text"
                        @blur="$v.role.name.$touch()"
                    />
                    <b-form-invalid-feedback :state="$v.role.name.$dirty ? !$v.role.name.$anyError : null">
                        Name is required
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-4">
            <b-col>
                <b-button variant="info" :disabled="$v.$invalid" @click="save">{{ $t('buttons.save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import httpClient from '@/services';

export default {
    name: 'EditRole',
    provide() {
        return {
            $v: this.$v
        };
    },
    validations: {
        role: {
            name: {
                required
            }
        }
    },
    data() {
        return {
            role: null,
            roleId: null
        };
    },
    mounted() {
        const { roleId } = this.$route.params;
        this.roleId = roleId;
        this.getRole();
    },
    methods: {
        getRole() {
            httpClient
                .get(`/roles/${this.roleId}`)
                .then(response => {
                    this.role = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        save() {
            delete this.role.createdAt;
            delete this.role.updatedAt;
            delete this.role.id;
            httpClient
                .post(`/roles/${this.roleId}`, this.role)
                .then(() => {
                    this.$router.push({ name: 'RoleSettings' });
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
