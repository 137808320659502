<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>Users</h1>
            </b-col>
            <b-col class="text-right">
                <b-button @click="createVisible = !createVisible">Create new user</b-button>
            </b-col>
        </b-row>
        <b-card>
            <b-table striped hover :items="items" :fields="fields">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'EditUserSettings', params: { userId: data.item.id } }">
                        {{ data.item.firstName }} {{ data.item.lastName }}
                    </router-link>
                </template>
                <template #cell(email)="data">
                    {{ data.item.email }}
                </template>
            </b-table>
        </b-card>
        <add-user-side-bar :show="createVisible" @hidden="createVisible = false" />
    </div>
</template>

<script>
import httpClient from '@/services';
import AddUserSideBar from '../../../components/AddUserSideBar.vue';
export default {
    components: { AddUserSideBar },
    name: 'UserSettings',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'email',
                    sortable: true
                }
            ],
            items: [],
            name: null,
            createVisible: false
        };
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        getUsers() {
            httpClient
                .get('/users')
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
