<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>Add Scorecard</h1>
            </b-col>
        </b-row>
        <category-form :category="category" />
        <b-row class="mt-4">
            <b-col>
                <b-button variant="info" :disabled="$v.$invalid" @click="save">{{ $t('buttons.save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { required, minValue, integer } from 'vuelidate/lib/validators';
import httpClient from '@/services';
import CategoryForm from '../../../components/CategoryForm.vue';

export default {
    components: { CategoryForm },
    name: 'ModifyCategorySettings',
    provide() {
        return {
            $v: this.$v
        };
    },
    validations: {
        category: {
            questions: {
                $each: {
                    question: {
                        required
                    },
                    questionType: {
                        required
                    },
                    weight: {
                        required,
                        minValue: minValue(1),
                        integer
                    }
                }
            }
        }
    },
    data() {
        return {
            category: {
                name: null,
                title: null,
                questions: [],
                roles: []
            }
        };
    },
    methods: {
        save() {
            httpClient
                .post('/categories', this.category)
                .then(() => {
                    this.$router.push({ name: 'CategorySettings' });
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
