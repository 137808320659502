<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>Group Details</h1>
            </b-col>
        </b-row>
        <b-card v-if="group">
            <b-row class="mt-4">
                <b-col>
                    <b-form-group id="input-group-1" label="Group name:" label-for="input-1">
                        <b-form-input id="input-1" v-model="group.name" type="text" required />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-form-group id="textarea-group-1" label="Description:" label-for="description">
                        <b-form-textarea
                            id="textarea"
                            v-model="group.description"
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-form-group label="Group Members:">
                        <b-form-row v-for="(item, index) in group.team" :key="index" class="mb-2">
                            <b-col cols="11">
                                <template v-if="item.id"> {{ item.firstName }} {{ item.lastName }} </template>
                            </b-col>
                            <b-col>
                                <b-icon-trash @click="deleteMember(index)"></b-icon-trash>
                            </b-col>
                        </b-form-row>
                    </b-form-group>
                    <user-select :placeholder="'Add member'" :classes="'mr-4'" @selected="addMember" />
                </b-col>
            </b-row>
        </b-card>
        <b-row class="mt-4">
            <b-col>
                <b-button variant="info" @click="save">{{ $t('buttons.save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import httpClient from '@/services';
import UserSelect from '../../../components/UserSelect.vue';
export default {
    components: { UserSelect },
    name: 'EditGroupSettings',
    data() {
        return {
            group: null,
            groupId: null,
            team: []
        };
    },
    mounted() {
        const { groupId } = this.$route.params;
        this.groupId = groupId;
        this.getGroup();
    },
    methods: {
        getGroup() {
            httpClient
                .get(`/groups/${this.groupId}`)
                .then(response => {
                    this.group = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addMember(user) {
            this.group.team.push(user);
        },
        deleteMember(index) {
            this.group.team.splice(index, 1);
        },
        save() {
            const members = [];
            for (const member of this.group.team) {
                members.push(member.id);
            }
            httpClient
                .post(`/groups/${this.groupId}`, {
                    ...this.group,
                    team: members
                })
                .then(() => {
                    this.$router.push({ name: 'GroupSettings' });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
