<template>
    <div>
        <b-sidebar
            id="sidebar-backdrop"
            v-model="showSideBar"
            :backdrop-variant="'dark'"
            backdrop
            shadow
            right
            @shown="showCreateRole"
            @hidden="closeSideBar"
        >
            <div class="px-3 py-2">
                <h3>Create User</h3>
                <b-form-group label="First Name" label-for="first-name">
                    <b-form-input id="first-name" v-model="user.firstName" ref="focusName" type="text"></b-form-input>
                </b-form-group>
                <b-form-group label="Last Name" label-for="last-name">
                    <b-form-input id="last-name" v-model="user.lastName" type="text"></b-form-input>
                </b-form-group>
                <b-form-group label="Email" label-for="email">
                    <b-form-input id="email" v-model="user.email" type="email"></b-form-input>
                </b-form-group>
                <b-row>
                    <b-col>
                        <b-button variant="info" @click="createUser">{{ $t('buttons.submit') }}</b-button>
                    </b-col>
                    <b-col class="text-right">
                        <b-button variant="secondary" v-b-toggle.sidebar-backdrop>{{ $t('buttons.cancel') }}</b-button>
                    </b-col>
                </b-row>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import httpClient from '@/services';
export default {
    name: 'AddUserSideBar',
    props: {
        show: Boolean
    },
    data() {
        return {
            showSideBar: false,
            user: {
                firstName: null,
                lastName: null,
                email: null
            }
        };
    },
    methods: {
        showCreateRole() {
            this.$refs.focusName.focus();
        },
        createUser() {
            httpClient
                .post('/users', this.user)
                .then(response => {
                    const userId = response.data.id;
                    this.$router.push({ name: 'EditUserSettings', params: { userId: userId } });
                })
                .catch(error => {
                    console.error(error);
                });
        },
        closeSideBar() {
            this.$emit('hidden');
        }
    },
    watch: {
        show: function(value) {
            this.showSideBar = value;
        }
    }
};
</script>

<style></style>
