<template>
    <div>
        <b-modal id="addRole" title="Select Role" @ok="selected">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <b-form-select v-model="selectedRole">
                            <b-form-select-option :value="null">Please select an option</b-form-select-option>
                            <b-form-select-option v-for="(item, index) in roles" :key="index" :value="item">
                                {{ item.name }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>
<script>
export default {
    name: 'CategoryAddModal',
    props: {
        roles: Array
    },
    data() {
        return {
            selectedRole: null
        };
    },
    methods: {
        selected() {
            this.$emit('selected', { role: this.selectedRole });
        }
    }
};
</script>
