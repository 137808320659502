<template>
    <div v-if="category">
        <b-row class="mt-4">
            <b-col>
                <h1>Edit Scorecard</h1>
            </b-col>
        </b-row>
        <category-form ref="categoryForm" :category="category" />
        <b-row class="mt-4">
            <b-col>
                <b-button variant="info" :disabled="$v.$invalid" @click="save">{{ $t('buttons.save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { required, minValue, integer } from 'vuelidate/lib/validators';
import httpClient from '@/services';
import CategoryForm from '../../../components/CategoryForm.vue';

export default {
    name: 'ModifyCategorySettings',
    components: { CategoryForm },
    provide() {
        return {
            $v: this.$v
        };
    },
    validations: {
        category: {
            questions: {
                $each: {
                    question: {
                        required
                    },
                    questionType: {
                        required
                    },
                    weight: {
                        required,
                        minValue: minValue(1),
                        integer
                    }
                }
            }
        }
    },
    data() {
        return {
            category: null,
            categoryId: null
        };
    },
    mounted() {
        const { categoryId } = this.$route.params;
        this.categoryId = categoryId;
        this.getCategory();
    },
    methods: {
        getCategory() {
            httpClient
                .get(`/categories/${this.categoryId}`)
                .then(response => {
                    this.category = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        save() {
            delete this.category.createdAt;
            delete this.category.updatedAt;
            delete this.category.id;
            httpClient
                .post(`/categories/${this.categoryId}`, this.category)
                .then(() => {
                    this.$router.push({ name: 'CategorySettings' });
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
