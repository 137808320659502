<template>
    <div>
        <b-row class="mt-4">
            <b-col>
                <h1>User</h1>
            </b-col>
        </b-row>
        <b-card v-if="user">
            <b-row>
                <b-col>
                    <b-form-group id="name-group" label-for="firstName" label-cols-sm="3" label="Name:">
                        <b-input-group>
                            <b-form-input id="firstName" v-model="user.firstName" :value="user.firstName" class="m-1" />
                            <b-form-input v-model="user.lastName" :value="user.lastName" class="m-1" />
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group id="name-group" label-for="email" label-cols-sm="3" label="Email:">
                        <b-form-input id="email" v-model="user.email" :value="user.email" class="m-1" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                        id="permission-group"
                        label-for="permission-select"
                        label-cols-sm="3"
                        label="Permission:"
                    >
                        <b-form-select v-model="user.permissions">
                            <b-form-select-option :value="0">Admin</b-form-select-option>
                            <b-form-select-option :value="5">Manager</b-form-select-option>
                            <b-form-select-option :value="99">Basic</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-form-group
                        v-slot="{ ariaDescribedby }"
                        id="role-group"
                        label-for="role-select"
                        label-cols-sm="3"
                        label="Primary Roles:"
                    >
                        <b-form-checkbox
                            v-for="option in roles"
                            v-model="selectedRoles"
                            :key="option.id"
                            :value="option"
                            :aria-describedby="ariaDescribedby"
                            name="selectedRoles"
                            button
                            :button-variant="selectedRoles.some(x => x.id === option.id) ? 'secondary' : 'light'"
                            class="m-1"
                        >
                            {{ option.name }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col sm="3">Manager:</b-col>
                <b-col>
                    <div v-if="manager">
                        {{ manager.firstName }} {{ manager.lastName }}
                        <b-icon-trash @click="deleteManager()"></b-icon-trash>
                    </div>
                    <user-select v-else :placeholder="'Select Manager'" :classes="'mr-4'" @selected="addManager" />
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-form-group label="Team Members:" label-cols-sm="3">
                        <b-form-row v-for="(item, index) in team" :key="index" class="mb-2">
                            <b-col cols="11">
                                <template v-if="item.id"> {{ item.firstName }} {{ item.lastName }} </template>
                            </b-col>
                        </b-form-row>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="mt-4">
                <b-col>
                    <b-form-group
                        v-slot="{ ariaDescribedby }"
                        id="groups-group"
                        label-for="groups-select"
                        label-cols-sm="3"
                        label="Groups:"
                    >
                        <b-form-checkbox
                            v-for="option in groups"
                            v-model="selectedGroups"
                            :key="option.id"
                            :value="option.id"
                            :aria-describedby="ariaDescribedby"
                            name="selectedGroups"
                            button
                            :button-variant="selectedGroups.some(x => x === option.id) ? 'secondary' : 'light'"
                            class="m-1"
                        >
                            {{ option.name }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-row class="mt-4">
            <b-col>
                <b-button variant="info" @click="save">{{ $t('buttons.save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import httpClient from '@/services';
import UserSelect from '../../../components/UserSelect.vue';
export default {
    components: { UserSelect },
    name: 'UserSettings',
    data() {
        return {
            user: null,
            roles: null,
            userId: null,
            role: null,
            permissions: null,
            manager: null,
            team: [],
            selectedRoles: [],
            groups: [],
            selectedGroups: []
        };
    },
    mounted() {
        const { userId } = this.$route.params;
        this.userId = userId;
        this.getUser();
        this.getRoles();
        this.getGroups();
    },
    methods: {
        getUser() {
            httpClient
                .get(`/user/${this.userId}`)
                .then(response => {
                    this.user = response.data.user;
                    this.user.permissions = response.data.permissions;
                    this.selectedRoles = response.data.primaryRoles;
                    this.team = response.data.team;
                    this.manager = response.data.reportsTo;
                    this.selectedGroups = response.data.groups;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getRoles() {
            httpClient
                .get('/roles')
                .then(response => {
                    this.roles = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getGroups() {
            httpClient
                .get('/groups')
                .then(response => {
                    this.groups = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addManager(user) {
            this.manager = user;
        },
        deleteManager() {
            this.manager = null;
        },
        save() {
            const roles = [];
            for (const role of this.selectedRoles) {
                roles.push(role.id);
            }
            let manager = null;
            if (this.manager) {
                manager = this.manager.id;
            }
            httpClient
                .post(`/user/${this.userId}`, {
                    user: {
                        firstName: this.user.firstName,
                        lastName: this.user.lastName,
                        email: this.user.email
                    },
                    roles: roles,
                    permissions: this.user.permissions,
                    reportsTo: manager,
                    groups: this.selectedGroups
                })
                .then(() => {
                    this.$store.commit('setTeam', []);
                    this.$router.push({ name: 'UserSettings' });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
</script>
