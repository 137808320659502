<template>
    <b-card>
        <b-row>
            <b-col cols="12">
                <b-form>
                    <b-form-group id="input-group-1" label="Name:" label-for="input-1">
                        <b-form-input id="input-1" v-model="category.name" type="text" required />
                    </b-form-group>
                    <b-form-group
                        id="input-group-2"
                        label="Title:"
                        label-for="input-2"
                        description="Title will be displayed when completing the scorecard"
                    >
                        <b-form-input id="input-2" v-model="category.title" type="text" required />
                    </b-form-group>
                    <b-form-group label="Questions:">
                        <b-row>
                            <b-col cols="7"></b-col>
                            <b-col cols="3" class="pl-0">Question Type</b-col>
                            <b-col cols="1" class="pl-0">Weight</b-col>
                        </b-row>
                        <draggable :list="category.questions" class="list-group">
                            <b-form-row
                                v-for="(item, index) in $v.category.questions.$each.$iter"
                                :key="index"
                                class="mb-2"
                            >
                                <b-col class="col-auto my-auto handle">
                                    <b-icon icon="grip-vertical"></b-icon>
                                </b-col>
                                <b-col>
                                    <b-form-input
                                        v-model="item.question.$model"
                                        type="text"
                                        required
                                        @blur="item.question.$touch()"
                                    />
                                    <b-form-invalid-feedback
                                        :state="item.question.$dirty ? !item.question.$anyError : null"
                                    >
                                        Question is required
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-select
                                        v-model="item.questionType.$model"
                                        :options="questionTypes"
                                    ></b-form-select>
                                </b-col>
                                <b-col cols="1">
                                    <b-form-input
                                        type="number"
                                        v-model="item.weight.$model"
                                        @blur="item.weight.$touch()"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                        :state="item.weight.$dirty ? !item.weight.$anyError : null"
                                    >
                                        Invalid
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col class="col-auto my-auto">
                                    <b-icon-trash @click="deleteQuestion(index)"></b-icon-trash>
                                </b-col>
                            </b-form-row>
                        </draggable>

                        <b-button @click="addQuestion">
                            New Question
                        </b-button>
                    </b-form-group>

                    <b-form-group
                        v-slot="{ ariaDescribedby }"
                        id="role-group"
                        label-for="role-select"
                        label="Applicable Roles:"
                    >
                        <b-form-checkbox
                            v-for="option in roles"
                            v-model="category.roles"
                            :key="option.id"
                            :value="option"
                            :aria-describedby="ariaDescribedby"
                            name="selectedRoles"
                            button
                            :button-variant="category.roles.some(x => x.id === option.id) ? 'secondary' : 'light'"
                            class="m-1"
                        >
                            {{ option.name }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <role-add-modal :roles="roles" @selected="selectedRole" />
    </b-card>
</template>
<script>
import draggable from 'vuedraggable';
import httpClient from '@/services';
import RoleAddModal from './RoleAddModal.vue';
export default {
    components: { RoleAddModal, draggable },
    name: 'CategoryForm',
    props: {
        category: Object
    },
    data() {
        return {
            questionTypes: [
                { value: 'YesNo', text: 'Yes / No Response' },
                { value: 'To5', text: 'Range out of 5' }
            ],
            roles: null
        };
    },
    mounted() {
        this.getRoles();
    },
    methods: {
        getRoles() {
            httpClient
                .get('/roles')
                .then(response => {
                    this.roles = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addQuestion() {
            this.category.questions.push({ question: null, questionType: 'YesNo', weight: 1 });
        },
        deleteQuestion(index) {
            this.category.questions.splice(index, 1);
        },
        addRole() {
            this.$bvModal.show('addRole');
        },
        deleteRole(index) {
            this.category.roles.splice(index, 1);
        },
        selectedRole(data) {
            this.category.roles.push(data.role);
        }
    },
    inject: ['$v']
};
</script>
