<template>
    <div>
        <b-row class="mt-4">
            <b-col cols="8">
                <h1>Scorecards</h1>
            </b-col>
            <b-col cols="4" class="text-right">
                <b-button :to="{ name: 'AddCategorySettings' }">Create new</b-button>
            </b-col>
        </b-row>
        <b-card>
            <b-table striped hover :items="items" :fields="fields">
                <template #cell(name)="data">
                    <router-link :to="{ name: 'EditCategorySettings', params: { categoryId: data.item.id } }">{{
                        data.item.name
                    }}</router-link>
                </template>
                <template #cell(delete)="data">
                    <div class="text-right">
                        <b-icon-trash @click="confirmDelete(data.item.id)"></b-icon-trash>
                    </div>
                </template>
            </b-table>
        </b-card>
    </div>
</template>

<script>
import httpClient from '@/services';

export default {
    name: 'CategorySettings',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'title',
                    sortable: true
                },
                {
                    key: 'delete',
                    label: '',
                    sortable: false
                }
            ],
            items: [],
            name: null,
            createVisible: false
        };
    },
    mounted() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            httpClient
                .get('/categories')
                .then(response => {
                    this.items = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        confirmDelete(categoryId) {
            this.$bvModal
                .msgBoxConfirm('Are you sure you want to delete this scorecard category?', {
                    title: 'Please Confirm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.deleteCategory(categoryId);
                    }
                })
                .catch(error => {
                    // An error occurred
                    console.error(error);
                });
        },
        deleteCategory(categoryId) {
            httpClient
                .delete(`/categories/${categoryId}`)
                .then(() => {
                    this.getCategories();
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>
